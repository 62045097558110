import { ClaimMintChibiListItem } from "./ClaimMintChibiListItem";

export const ClaimMintChibiListView = ({ chibis, selectedChibis, onSelect }) => {
    return (
        <div className={'claim-mint-item-container'}>
            {
                chibis?.map((chibi) => {
                    const key = `${chibi?.contractAddress}-${chibi?.tokenId}`;
                    const selected = selectedChibis?.find(({ contractAddress, tokenId }) => {
                        return `${contractAddress}-${tokenId}` === key;
                    });

                    return (
                        <ClaimMintChibiListItem
                            key={key}
                            chibi={chibi}
                            selected={selected}
                            onSelect={onSelect}
                        />
                    );
                })
            }
        </div>
    );
};