import { useState } from "react"
import LazyLoad from "react-lazyload";
import { ModelViewer } from "../components/ModelViewer";
import './Viewer.scss'

const ANIMATIONS = [
    "Idle",
    "Walk",
    "Run",
    "Roll",
    "Backflip",
    "Circle"
]

export function Viewer() {
    const [animation, setAnimation] = useState('Idle');

    return (
        <>
            <section className="viewer">
                <div className="container">
                    <h1 className="title centered-text">Train your fren</h1>
                    <LazyLoad offset={300}>
                        <div className="model-viewer-container">
                            <div>
                                {
                                    ANIMATIONS.slice(0, 3).reverse().map((animation) => (
                                        <button
                                            key={animation}
                                            className="button viewer-button"
                                            onClick={() => setAnimation(animation)}
                                        >{animation}</button>
                                    ))
                                }
                            </div>
                            <ModelViewer
                                size={{ height: 300, width: 300 }}
                                link="/glb/CatAnimations.glb"
                                animation={animation}
                            />
                            <div>
                                {
                                    ANIMATIONS.slice(3, 6).reverse().map((animation) => (
                                        <button
                                            key={animation}
                                            className="button viewer-button"
                                            onClick={() => setAnimation(animation)}
                                        >{animation}</button>
                                    ))
                                }
                            </div>
                        </div>
                    </LazyLoad>
                </div>
                <img src="images/cloud-top.png" className="cloud-top" />
            </section>
        </>
    )
}