import LazyLoad from "react-lazyload";
import { useCallback, useState } from "react";
import { connect } from "../utilities/connect";
import { MintProvider, STATUS, useMintContext } from "../components/MintProvider";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { getAllowListProof } from "../utilities/api";
import { Helmet } from "react-helmet-async";

const AllowListCheck = () => {
    const {
        setStatus = () => { },
        setConnecting = () => { },
        connecting,
    } = useMintContext();

    const [resultMessage, setResultMessage] = useState('');

    window.ethereum?.on('accountsChanged', async () => {
        setResultMessage()
    })

    const onButtonClicked = useCallback(async () => {
        setConnecting(true);
        try {
            const { address, signer } = await connect();
            const proof = await getAllowListProof({ address, signer });
            setResultMessage((
                proof?.length
                    ? '🎉 </br> Congratulations fren, </br> you are on the allow-list!'
                    : `😔 </br> We're sorry fren, </br> you are not on the list!`
            ))
        } catch (error) {
            console.log('connect', error)
            setStatus({
                type: STATUS.ERROR,
                message: error?.error?.message || error?.reason || error?.message
            });
        } finally {
            setConnecting(false);
        }
    }, [setConnecting, setStatus, setResultMessage])

    console.log(`[AllowListCheck] connecting: ${connecting}`);

    return (
        <>
            <Helmet>
                <title>Chibi Frens - Allow List Check</title>
            </Helmet>
            <div className="col-2 centered">
                <LazyLoad offset={300}>
                    <video src="videos/walk.mp4" muted autoPlay loop playsInline />
                </LazyLoad>
            </div>
            <div className="col-2 centered">
                <h1 className={'centered-text'}>Allow List Check</h1>
                {resultMessage && resultMessage.length > 0 ?
                    <>
                        <h3 className={'centered-text'} dangerouslySetInnerHTML={{ __html: resultMessage }} />
                    </> :
                    <>
                        <h3 className={'centered-text'}>Check if you are on the Chibi Frens mint allow list.</h3>
                        <div onClick={onButtonClicked} className="button mint">
                            <img src={`svg/button.svg`} />
                            {connecting ? <LoadingSpinner /> : <span>Check</span>}
                        </div>
                    </>
                }

            </div>
        </>
    )
}

export const AllowListCheckWithProvider = (props) => {
    return (
        <MintProvider showBackButton={true}>
            <AllowListCheck {...props} />
        </MintProvider>
    )
}

