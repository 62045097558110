import { useEffect } from 'react';
import { DISCORD_URL } from '../constants';
import { Button } from './Button';
import './Navigation.scss'

export function Navigation() {
    function handleClick() {
        const x = document.getElementById("links");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
    }

    useEffect(() => {
        function handleResize() {
            const links = document.getElementById("links");
            if (window.innerWidth > 600) {
                links.style.display = "flex";
            } else {
                links.style.display = "none";
            }
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="topnav">
            <div className="container">
                <a href="/" className="logo">
                    <img src="images/logo.png" />
                </a>
                <div className="navigation" id="links">
                    <a href="#about">About</a>
                    <a href="#evolve">Evolve</a>
                    <a href="#faq">FAQ</a>
                    <a href="//game.chibilabs.io" target="_blank" rel="noreferrer">Game</a>
                    <a href="//docs.chibilabs.io" target="_blank" rel="noreferrer">Docs</a>
                    <Button type="join-discord" onClick={
                        () => window.open(DISCORD_URL, '_blank').focus()
                    } />
                </div>
                <span className="burger-menu" onClick={handleClick}>
                    <img src="svg/menu.svg" />
                </span>
            </div>
        </div>
    )
}