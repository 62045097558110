import { STATUS, useMintContext } from "./MintProvider";
import './MintStatus.scss';

export const MintStatus = () => {

    const {
        status = {},
    } = useMintContext()

    const {
        type = STATUS.NONE,
        message = '',
    } = status;

    let style = '';
    if (type === STATUS.ERROR && message.length > 0) {
        style = 'error-status';
    } else if (type === STATUS.SUCCESS && message.length > 0) {
        style = 'success-status';
    } else if (type === STATUS.INFO && message.length > 0) {
        style = 'info-status';
    }

    return (
        <div className={`status ${style}`}>
            <p dangerouslySetInnerHTML={{ __html: status?.message }} />
        </div>
    )
}