export const WEBSITE_URL = "//ChibiLabs.io";
export const DISCORD_URL = "//discord.gg/ChibiLabs";
export const TWITTER_URL = "//twitter.com/ChibiLabs";
export const INSTAGRAM_URL = "//instagram.com/Chibi_Labs";
export const LINKEDIN_URL = "//linkedin.com/company/ChibiLabs/";
export const MEDIUM_URL = "//medium.com/@ChibiLabs";
export const API_URL = "https://api.chibilabs.dev";
export const GAME_DOCS = "https://docs.chibilabs.io/chibi-frens-game/game";
export const ALCHEMY_API_KEY = "20LjoZaMxhhLv4UP1n4BWIIoZh_n8m4Z";
export const NFT_STORAGE_CONTRACT = "0x916CEDFdE8563c4d3E11B2Aa4d9b2624a409Ae31";

export const TIMES = {
    ALLOW_LIST_SALE: "Tue Apr 12 2022 22:00:00 GMT",
    PUBLIC_SALE: "Tue Apr 13 2022 22:00:00 GMT",
    CLAIM: "Tue Apr 14 2022 22:00:00 GMT",
};


export const PRICE = 0.05;

const CONTRACTS = {
    ropsten: {
        id: "ropsten",
        chibis_contracts: [
            {
                id: "gen1",
                description: "Chibi Genesis",
                address: "0xeA9A006D14E4E1C996F5E95E4951100D1C54e7eb",
            },
            {
                id: "gen2",
                description: "Chibi Apes",
                address: "0x6A50B3F5809622fad3A7B5ceFe2793680d8DcA6f",
            },
            {
                id: "gen3",
                description: "Chibi Galaxy",
                address: "0x4567E8987382d975C87e244E7A6FB4A79011D033",
            },
            {
                id: "gen4",
                description: "Chibi Frens",
                address: "0x712d98BE3f872D58bdE337114C6893745BDaE537"
            }
        ],
    },
    homestead: {
        id: "homestead",
        chibis_contracts: [
            {
                id: "gen1",
                description: "Chibi Genesis",
                address: "0x4Ef0Fe82B42B6104BbcEB69E764AbD2050aCfdd4"
            },
            {
                id: "gen2",
                description: "Chibi Apes",
                address: "0xC49a9AB342b6ea66792D4110e9cA0ab36e3a5674"
            },
            {
                id: "gen3",
                description: "Chibi Galaxy",
                address: "0x5AEb2a703323F69b20F397BCB7B38610EC37237b"
            },
            {
                id: "gen4",
                description: "Chibi Frens",
                address: "0x65922260237378b75Fd2FEf9f6236ab1dD5776AA"
            }
        ],
    },
};

export const COLLECTIONS_CONTRACT = CONTRACTS["homestead"];

export const getChibiContractInfo = (id) => {
    const contract = COLLECTIONS_CONTRACT.chibis_contracts.find(contract => contract.id === id);
    return contract?.address?.toLowerCase();
};
