import { Helmet } from "react-helmet-async";
import { MintProvider } from "../components/MintProvider";

export const ClaimOver = () => {
    return (
        <MintProvider>
            <Helmet>
                <title>Chibi Frens - Claim</title>
            </Helmet>
            <div className="col-12 centered">
                <h1 className={'centered-text'}>Chibi Fren Claim</h1>
                <h3 className={'centered-text'}>We hope you have enjoyed participating in the Chibi Fren Claim. <br></br>The Chibi Fren Claim is now over!</h3>
            </div>
        </MintProvider>
    );
};
