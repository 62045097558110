import LazyLoad from "react-lazyload";
import './Links.scss';
import { DISCORD_URL, INSTAGRAM_URL, LINKEDIN_URL, MEDIUM_URL, TWITTER_URL } from '../constants'

export function Links() {
    return (
        <LazyLoad offset={300} className="links">

            <a href={DISCORD_URL} target="_blank" rel="noreferrer">
                <img src="svg/links-discord.svg" className="links-icon" />
            </a>
            <a href={TWITTER_URL} target="_blank" rel="noreferrer">
                <img src="svg/links-twitter.svg" className="links-icon" />
            </a>
            <a href={INSTAGRAM_URL} target="_blank" rel="noreferrer">
                <img src="svg/links-instagram.svg" className="links-icon" />
            </a>
            <a href={LINKEDIN_URL} target="_blank" rel="noreferrer">
                <img src="svg/links-linkedin.svg" className="links-icon" />
            </a>
            <a href={MEDIUM_URL} target="_blank" rel="noreferrer">
                <img src="svg/links-medium.svg" className="links-icon" />
            </a>
        </LazyLoad>
    )
}