import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ClaimMintChibiListView } from "../components/ClaimMintChibiListView";
import { ConnectButton } from "../components/ConnectButton";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { MintProvider, STATUS, useMintContext } from "../components/MintProvider";
import { getChibiContractInfo, NFT_STORAGE_CONTRACT } from "../constants";
import { fetchGen1sWithClaimability } from "../utilities/utils";
import NFTStorage from "../abis/NFTStorage.json";
import { connect } from "../utilities/connect";
import { alchemy } from "../utilities/alchemy";
import { createErrorMessage, getRandomItems } from "../utilities/misc";

function GenesisClaimComponent() {
    let [chibis, setChibis] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [claimInProgress, setClaimInProgress] = useState(false);
    const [selectedChibi, setSelectedChibi] = useState();
    const { walletInfo = {}, setStatus } = useMintContext();
    const { address } = walletInfo;

    function handleSelect(chibi, action) {
        if (action === "add") {
            setSelectedChibi(chibi);
        } else if (action === "remove") {
            setSelectedChibi();
        }
    }

    async function handleClaim() {
        try {
            if (!selectedChibi) {
                throw new Error("Please select a Chibi!");
            }
            setClaimInProgress(true);
            const acceptedTokenId = parseInt(selectedChibi.tokenId);
            const claimAmount = acceptedTokenId <= 154 ? 2 : 5;

            const gen4 = getChibiContractInfo("gen4");
            const claimable = await alchemy.nft.getNftsForOwner(
                NFT_STORAGE_CONTRACT, { contractAddresses: [gen4] }
            );
            const claimableTokenIds = claimable?.ownedNfts?.map((t) => t.tokenId);
            const randomIds = getRandomItems(claimableTokenIds, claimAmount);

            const { signer } = await connect();
            const contract = new ethers.Contract(NFT_STORAGE_CONTRACT, NFTStorage, signer);
            const tx = await contract.claimNFT(acceptedTokenId, randomIds);
            await tx.wait();
        } catch (error) {
            console.log("ERROR", error.message);
            setStatus(createErrorMessage(error));
        } finally {
            setClaimInProgress(false);
        }
    }

    useEffect(() => {
        async function getChibis() {
            if (!address) {
                return;
            }
            try {
                setLoading(true);
                const gen1s = await fetchGen1sWithClaimability(address);
                setChibis(gen1s);
            } catch (error) {
                console.error(error);
                setStatus({
                    type: STATUS.ERROR,
                    message: 'There is an issue retrieving your Chibis!'
                });
            } finally {
                setLoading(false);
            }
        }
        getChibis();
    }, [address]);

    return (
        <>
            <Helmet>
                <title>Chibi Frens - Genesis Claim</title>
            </Helmet>
            <div className="col-12 centered">
                <h1 className="centered-text">New Chibi Genesis Program Claim</h1>
                {
                    !address
                        ? <div className={'claim-mint'}>
                            <h3 className={'centered-text'}>Connect to start claiming your Chibi Frens.</h3>
                            <ConnectButton />
                        </div> : null
                }
                {
                    address && isLoading && (
                        <>
                            <h3 className={'centered-text'}>Getting your list of Chibis...</h3>
                            <LoadingSpinner />
                        </>
                    )
                }
                {
                    address && !isLoading && chibis.length && (
                        <>
                            <h3 className={'centered-text'}>
                                Select one Chibi:
                            </h3>
                            <ClaimMintChibiListView
                                chibis={chibis}
                                selectedChibis={selectedChibi ? [selectedChibi] : []}
                                onSelect={handleSelect}
                            />
                            <div className="claim-mint">
                                <div className={'button mint'} onClick={handleClaim}>
                                    <img src={`svg/button.svg`} alt={'Claim Button'} />
                                    {claimInProgress ? <LoadingSpinner /> : <span>Claim</span>}
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    address && !isLoading && !chibis?.length && (
                        <h3 className={'centered-text'}>
                            No Chibi Genesis found!
                        </h3>

                    )
                }
            </div>
        </>
    );
}

export function GenesisClaim() {
    return (
        <MintProvider>
            <GenesisClaimComponent />
        </MintProvider>
    );
}