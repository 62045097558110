import { useMintContext } from "./MintProvider";
import { LoadingSpinner } from "./LoadingSpinner";

export const ConnectButton = (props) => {
    const {
        walletInfo = {},
        handleConnect,
        connecting,
    } = useMintContext();

    let {
        onClick = handleConnect,
    } = props;

    const {
        address,
    } = walletInfo;

    if (address) {
        return null;
    }

    return (
        <div onClick={onClick} className="button mint">
            <img src={`svg/button.svg`} />
            {connecting ? <LoadingSpinner /> : <span>Connect</span>}
        </div>
    );
};