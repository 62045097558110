import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./routes/Home";
import { AllowListCheckWithProvider } from "./routes/AllowListCheck";
import { AllowListMintWithProvider, PublicMintWithProvider } from "./routes/Mint";
import { ClaimMintWithProvider } from "./routes/ClaimMint";
import { CheckWithProvider } from "./routes/Check";
import { ClaimOver } from "./routes/ClaimOver";
import { GenesisClaim } from "./routes/GenesisClaim";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path={"/allow-list-check"} element={<AllowListCheckWithProvider />} />
                <Route path={"/allow-list"} element={<AllowListMintWithProvider />} />
                <Route path={"/public"} element={<PublicMintWithProvider />} />
                <Route path={"/claim"} element={<ClaimOver />} />
                <Route path={"/special-claim"} element={<ClaimMintWithProvider type={'special-claim'} />} />
                <Route path={"/genesis-claim"} element={<GenesisClaim />} />
                <Route path={"/check"} element={<CheckWithProvider />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
