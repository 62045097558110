export const ClaimMintChibiListItem = ({ chibi, selected, onSelect }) => {
    const {
        contract,
        isClaimed = false,
        tokenId,
        media,
        name,
    } = chibi;

    const handleClick = () => {
        if (isClaimed) {
            return;
        }
        const action = (selected ? 'remove' : 'add');
        onSelect(chibi, action);
    };

    let className = 'claim-mint-item';
    if (selected && !isClaimed) {
        className += ' selected';
    } else if (isClaimed) {
        className += ' claimed';
    }

    const key = `${contract?.address}-${tokenId}`;
    const src = media?.[0]?.thumbnail || media?.[0]?.raw;

    return (
        <div key={key} id={key} className={className} onClick={handleClick}>
            <div className="overlay" />
            <img src={src} alt={name} />
        </div>
    );
};