import { MintProvider, STATUS, useMintContext } from "../components/MintProvider";
import { useEffect, useState } from "react";
import { getChibisForAddress } from "../utilities/utils";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ConnectButton } from "../components/ConnectButton";
import { claim, getGen1Chibis, getGen2And3Chibis, isEligibleForSpecialClaim, specialClaim } from "../utilities/mint";
import { getChibiContractInfo } from "../constants";
import { CollectionInfo } from "../components/CollectionInfo";
import { ethers } from "ethers";
import FrensABI from "../abis/frensABI.json";
import { ClaimMintChibiListView } from "../components/ClaimMintChibiListView";
import { Helmet } from "react-helmet-async";
import { createError } from "../utilities/error";

const ClaimMint = ({ type = 'check' }) => {
    let [chibis, setChibis] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [claimInProgress, setClaimInProgress] = useState(false);
    const [selectedChibis, setSelectedChibis] = useState([]);

    const isSpecialClaimRoute = type === 'special-claim';
    const isClaimRoute = type === 'claim';

    const { walletInfo = {}, setStatus = () => { } } = useMintContext();
    const { address, provider, signer } = walletInfo;

    const eligibleForSpecialClaim = isSpecialClaimRoute ? isEligibleForSpecialClaim(chibis) : false;
    chibis = isSpecialClaimRoute ? getGen1Chibis(chibis) : chibis;
    chibis = isClaimRoute ? getGen2And3Chibis(chibis) : chibis;

    const routeLabel = isSpecialClaimRoute ? 'Special ' : '';
    const tokensFound = chibis?.length;

    const getChibis = async () => {
        if (address) {
            try {
                setLoading(true);
                const chibis = await getChibisForAddress({ address, provider });
                setChibis(chibis);
            } catch (error) {
                console.error(error);
                setStatus({
                    type: STATUS.ERROR,
                    message: 'There is an issue retrieving your Chibis!'
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const selectedChibisDispatcher = ({ type, chibi }) => {
        setSelectedChibis((prevState) => {
            const newState = [...prevState];
            const currentIndex = newState.findIndex(item => item["tokenId"] === chibi["tokenId"]);

            if (type === 'add' && currentIndex < 0) {
                newState.push(chibi);
            } else if (type === 'remove' && currentIndex >= 0) {
                newState.splice(currentIndex, 1);
            }
            return newState;
        });
    };

    const handleSpecialClaim = async () => {
        setStatus();
        setClaimInProgress(true);

        const tokenIds = selectedChibis.map((chibi) => chibi["tokenId"]);
        try {
            const receipt = await specialClaim({ tokenIds, signer });
            setStatus({
                type: STATUS.SUCCESS,
                message: `<a href="https://etherscan.io/tx/${receipt?.hash}" target="_blank" rel="noreferrer">Check out your transaction on Etherscan</a>.`,
            });
            setSelectedChibis([]);
            const contractInfo = getChibiContractInfo("gen4");
            const contract = new ethers.Contract(contractInfo, FrensABI, provider);
            contract.on("SpecialClaim", (sender, amount) => {
                if (sender === address) {
                    console.log(`[ClaimMint][onSpecialClaim] `, { sender, amount });
                    contract.removeAllListeners("SpecialClaim");
                    getChibis();
                }
            });

        } catch (error) {
            console.error(error);
            let message = createError(error);
            if (message === "execution reverted: No claim.") {
                message = "Claiming has not started yet!";
            }
            setStatus({
                type: STATUS.ERROR,
                message
            });

        } finally {
            setClaimInProgress(false);
        }
    };

    const handleClaim = async () => {
        setStatus();
        setClaimInProgress(true);

        const gen1TokenIds = [];
        const gen2TokenIds = [];
        const gen3TokenIds = [];

        selectedChibis.forEach((chibi) => {
            const { contractAddress, tokenId } = chibi;
            if (contractAddress === getChibiContractInfo("gen1")) {
                gen1TokenIds.push(tokenId);
            } else if (contractAddress === getChibiContractInfo("gen2")) {
                gen2TokenIds.push(tokenId);
            } else if (contractAddress === getChibiContractInfo("gen3")) {
                gen3TokenIds.push(tokenId);
            }
        });

        try {
            const receipt = await claim({ gen1TokenIds, gen2TokenIds, gen3TokenIds, signer });
            setStatus({
                type: STATUS.SUCCESS,
                message: `<a href="https://etherscan.io/tx/${receipt?.hash}" target="_blank" rel="noreferrer">Check out your transaction on Etherscan</a>.`,
            });
            setSelectedChibis([]);
            const contractInfo = getChibiContractInfo("gen4");
            const contract = new ethers.Contract(contractInfo, FrensABI, provider);
            contract.on("Transfer", (from, to, tokenId) => {
                if (to === address) {
                    console.log(`[ClaimMint][onTransfer] `, { to, tokenId });
                    contract.removeAllListeners("Transfer");
                    getChibis();
                }
            });
        } catch (error) {
            console.error(error);
            let message = createError(error);
            if (message === "execution reverted: No claim.") {
                message = "Claiming has not started yet!";
            }
            setStatus({
                type: STATUS.ERROR,
                message,
            });
        } finally {
            setClaimInProgress(false);
        }
    };

    const handleSelect = (chibi, action) => {
        selectedChibisDispatcher({ type: action, chibi });
    };

    const Buttons = () => {
        const unclaimed = chibis.filter(chibi => !chibi?.isClaimed);
        return address && !isLoading && tokensFound && (
            <div className="buttons">
                <button onClick={() => setSelectedChibis(unclaimed)}>Select All</button>
                <button onClick={() => setSelectedChibis([])}>Deselect All</button>
            </div>
        );
    };

    useEffect(() => {
        getChibis();
    }, [address, provider]);

    return (
        <>
            <Helmet>
                <title>Chibi Frens - {routeLabel}Claim</title>
            </Helmet>
            <div className="col-12 centered">
                <h1 className={'centered-text'}>{routeLabel}Chibi Fren Claim</h1>
                {
                    !address
                        ? <div className={'claim-mint'}>
                            <h3 className={'centered-text'}>Connect to start claiming your Chibi Frens.</h3>
                            <ConnectButton />
                        </div> : null
                }
                {/* REGULAR CLAIM - Show only if not special claim eligible and /claim view */}
                {
                    isClaimRoute && address && !isLoading && tokensFound ? (
                        <>
                            <h3 className={'centered-text'}>Choose the Chibis you want to claim a fren for:</h3>
                            <Buttons />
                            <ClaimMintChibiListView
                                chibis={chibis}
                                selectedChibis={selectedChibis}
                                onSelect={handleSelect}
                            />
                            <div className="claim-mint">
                                <div className={'button mint'} onClick={handleClaim}>
                                    <img src={`svg/button.svg`} alt={'Claim Button'} />
                                    {claimInProgress ? <LoadingSpinner /> : <span>Claim</span>}
                                </div>
                                <CollectionInfo />
                            </div>
                        </>
                    ) : null
                }

                {/* SPECIAL CLAIM - Show on the /claim and /special-claim views if eligible */}
                {
                    isSpecialClaimRoute && address && !isLoading && tokensFound && eligibleForSpecialClaim ? (
                        <>
                            <h3 className={'centered-text'}>Choose the Chibis you want to claim a holo fren for:</h3>
                            <Buttons />
                            <ClaimMintChibiListView
                                chibis={chibis}
                                selectedChibis={selectedChibis}
                                onSelect={handleSelect}
                            />
                            <div className="claim-mint">
                                <div className={'button mint'} onClick={handleSpecialClaim}>
                                    <img src={`svg/button.svg`} alt={'Claim Button'} />
                                    {claimInProgress ? <LoadingSpinner /> : <span>Claim</span>}
                                </div>
                                <CollectionInfo />
                            </div>
                        </>
                    ) : null
                }

                {/* Special Claim View - not eligible. */}
                {
                    isSpecialClaimRoute && address && !isLoading && !eligibleForSpecialClaim && (
                        <>
                            <h3 className={'centered-text'}>
                                Sorry you are not eligible for a special Chibi Fren claim.
                            </h3>
                        </>
                    )
                }
                {
                    address && !isLoading && !tokensFound && (
                        <>
                            <h3 className={'centered-text'}>Sorry no Chibis found.</h3>
                        </>
                    )
                }
                {
                    address && isLoading && (
                        <>
                            <h3 className={'centered-text'}>Getting your list of Chibis...</h3>
                            <LoadingSpinner />
                        </>
                    )
                }
            </div>
        </>
    );
};

export const ClaimMintWithProvider = (props) => {
    return (
        <MintProvider showConnectButton={false} showBackButton={true}>
            <ClaimMint {...props} />
        </MintProvider>
    );
};