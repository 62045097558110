import LazyLoad from "react-lazyload";
import { RectangleBox } from "../components/RectangleBox";
import './Footer.scss';
import { TEXT } from '../text';
import { DISCORD_URL, WEBSITE_URL } from "../constants";
import { Links } from "../components/Links";

export function Footer() {
    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="centered">
                        <RectangleBox title={TEXT.CTA[0]} text={TEXT.CTA[1]} buttonText="Join Discord" skew={false} onClick={
                            () => window.open(DISCORD_URL, '_blank').focus()
                        } />
                    </div>

                </div>
                <img src="images/milk.png" className="milk-image" />
                <img src="images/mountain.png" className="mountain-image" />
                <img src="svg/bottom.svg" className="footer-image" />
            </div>
            <div className="footer-content">
                <div className="container">
                    <LazyLoad offset={300} className="centered">
                        <a href={WEBSITE_URL} target="_blank" rel="noreferrer">
                            <img src="images/logo-chibilabs.png" className="logo" />
                        </a>
                    </LazyLoad>
                    <Links />
                    <div className="centered centered-text">
                        <p>© Chibi Labs - Est. 2021</p>
                    </div>
                </div>
            </div>
        </>
    )
}