import { YouTube } from '../components/YouTube';
import './Ready.scss';

export function Ready() {
    return (
        <section className="ready centered">
            <div className="container">
                <h1>Are you ready for the mission?</h1>
                <YouTube embedId="CkP1fi7Hg0o" />
            </div>
            <img src="images/planet-2.png" className="planet-2" />
            <img src="images/planet-4.png" className="planet-4" />
            <img src="images/planet-5.png" className="planet-5" />
            <img src="images/waves.png" className="waves" />
            <img src="images/waves-stars.png" className="waves-stars" />
            <img src="images/cloud-bottom.png" className="cloud-bottom" />
        </section>
    )
}