import './Stars.scss';

export function Stars() {
    return (
        <div className="stars">
            <div id='star1'></div>
            <div id='star2'></div>
            <div id='star3'></div>
        </div>
    )
}