import { ethers } from "ethers";
import { ALCHEMY_API_KEY, getChibiContractInfo, NFT_STORAGE_CONTRACT } from "../constants";
import FrensABI from "../abis/frensABI.json";
import NFTStorage from "../abis/NFTStorage.json";
import { alchemy } from "./alchemy";

const provider = ethers.providers.getDefaultProvider("homestead", {
    alchemy: ALCHEMY_API_KEY
});

async function fetchGen1Chibis(address) {
    const gen1 = getChibiContractInfo("gen1");
    const nfts = await alchemy.nft.getNftsForOwner(address, { contractAddresses: [gen1] });
    return nfts.ownedNfts;
}

async function checkClaimedInNFTStorage(tokenIds) {
    const contract = new ethers.Contract(NFT_STORAGE_CONTRACT, NFTStorage, provider);
    return contract.checkClaimed(tokenIds);
}

export async function fetchGen1sWithClaimability(address) {
    const gen1s = await fetchGen1Chibis(address);
    const tokenIds = gen1s?.map((t) => t.tokenId);
    const claimableTokenIds = await checkClaimedInNFTStorage(tokenIds);
    return gen1s?.map((token, index) => {
        return {
            ...token,
            isClaimed: claimableTokenIds?.[index],
        };
    });
}

export async function getChibisForAddress({ address } = {}) {
    const gen1 = getChibiContractInfo("gen1");
    const gen2 = getChibiContractInfo("gen2");
    const gen3 = getChibiContractInfo("gen3");

    const nfts = await alchemy.nft.getNftsForOwner(address, { contractAddresses: [gen1, gen2, gen3] });

    const formatted = nfts?.ownedNfts?.map(async (token) => {
        const { contract: { address: contractAddress }, tokenId } = token;
        const isClaimed = await hasChibiBeenUsedToClaim({ contractAddress, tokenId, provider });

        return {
            ...token,
            isClaimed
        };
    });
    return Promise.all(formatted);
}

export async function hasChibiBeenUsedToClaim({ contractAddress, tokenId, provider } = {}) {
    const contractInfo = getChibiContractInfo("gen4");
    const contract = new ethers.Contract(contractInfo, FrensABI, provider);
    return await contract.isClaimed(contractAddress, tokenId);
}