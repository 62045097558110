import LazyLoad from "react-lazyload";
import { RectangleBox } from "../components/RectangleBox";
import './About.scss'
import { TEXT } from '../text';

export function About() {
    return (
        <section className="about" id="about">
            <div className="container flex-grid">
                <div className="col-2 centered">
                    <h1 className="title">{TEXT.ABOUT[0]}</h1>
                    <RectangleBox text={TEXT.ABOUT[1]} />
                </div>
                <div className="col-2 centered">
                    <LazyLoad offset={300}>
                        <video src="videos/walk.mp4" muted autoPlay loop playsInline />
                    </LazyLoad>
                </div>
            </div>
        </section>
    )
}