import axios from "axios";
import * as Constants from "../constants";

export const getProof = async ({ address } = {}) => {
    const result = await axios({
        method: 'get',
        url: `${Constants.API_URL}/whitelist?address=${address}`,
    });
    return result?.data
}

export const getAllowListProof = async ({ address, signer } = {}) => {
    const message = JSON.stringify({ address });
    const signature = await signer.signMessage(message);

    const result = await axios({
        method: 'get',
        url: `${Constants.API_URL}/fren-allow-list/${address}`,
        headers: { 'signature': signature },
    });
    return result?.data
}