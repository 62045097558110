import './Evolve.scss';
import { Slider } from '../components/Slider'

export function Evolve() {
    return (
        <section className="evolve" id="evolve">
            <div className="container">
                <img src="images/planet-8.png" className="planet-8" />
                <img src="images/planet-6.png" className="planet-6" />
                <div className="centered">
                    <h1 className="title centered-text">Collect, Train, Evolve</h1>
                </div>
                <div className="flex-grid">
                    <div className="col-3">
                        <div className="evolve-card">
                            <h4 className="evolve-title">Level 1</h4>
                            <Slider images={[
                                "images/preview/preview-panda-1.jpg",
                                "images/preview/preview-cat-1.jpg",
                                "images/preview/preview-dino-1.jpg",
                                "images/preview/preview-pig-1.jpg",
                            ]} />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="evolve-card">
                            <h4 className="evolve-title">Level 2</h4>
                            <Slider images={[
                                "images/preview/preview-panda-2.jpg",
                                "images/preview/preview-cat-2.jpg",
                                "images/preview/preview-dino-2.jpg",
                                "images/preview/preview-pig-2.jpg",
                            ]} />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="evolve-card">
                            <h4 className="evolve-title">Level 3</h4>
                            <Slider images={[
                                "images/preview/preview-panda-3.jpg",
                                "images/preview/preview-cat-3.jpg",
                                "images/preview/preview-dino-3.jpg",
                                "images/preview/preview-pig-3.jpg",
                            ]} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}