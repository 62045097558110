import './RectangleBox.scss';

export function RectangleBox({ title, text, onClick, buttonText, skew = true }) {
    return (
        <div className={`rectangle-box ${skew ? 'skew': ''}`}>
            {
                title && (
                    <h1>{title}</h1>
                )
            }
            <p className="rectangle-text">
                {text}
            </p>
            {
                buttonText && (
                    <button onClick={onClick}>{buttonText}</button>
                )
            }
        </div>
    )
}