export const TEXT = {
    ABOUT: [
        "What is a Chibi Fren?",
        "Companionship isn’t just for the real world. Despite their cool demeanor, Chibis can get lonely, too, which is why we decided to introduce Chibi Frens. They are your Chibi’s partner and a necessary guide to the Chibiverse. Pair your Chibi with a Fren to maximize the Chibi experience."],
    GAME: [
        "The Chibi Frens Mini-Game",
        "What came first – the fren or the egg? Raise your Chibi Fren from an egg to a baby to a full grown pet. This transformation will take place over a series of missions that allow you to level up your Chibi Frens. At maturity, you will have the ability to enhance your Frens into high performance gamers."
    ],
    CTA: [
        "What are you waiting for?",
        "Grab a Chibi Genesis, Ape or Galaxy today to claim your free (+ gas) Chibi Frens. Not a Chibi owner, participate in the Chibi Frens mint on 4/12."
    ],
    FAQ: [
        [
            "Wen Mint?",
            `
                <ul>
                    <li>Allow-list mint: 04/12 at 6pm EST</li>
                    <li>Public mint: 04/13 at 6pm EST (remaining supply, open end)</li>
                    <li>Special claim: 04/14 at 2pm EST</li>
                    <li>Holders claim: 04/14 at 5pm EST</li>
                </ul>
            `
        ],
        [
            "How can I mint?",
            "Chibi Frens will be minting on 4/12 at 6pm EST or 10pm UTC. Chibi Frens are priced at 0.05 ETH per mint (+ gas). We will announce the exact URL right before the sale."
        ],
        [
            "What is the utility?",
            "Chibi Frens will be a necessary piece to future gamified experiences in the Chibiverse. Each Fren will have its own traits, unique design and key attributes that dictate their value and utility for future gameplay. In addition, Chibi Frens enjoy the same utility as the Chibis: Full IP ownership, access to rigged 3D files, regular art airdrops community events, and the vibrant Chibis alpha group."
        ],
        [
            "How does the game work?",
            `
                Chibi Frens are born as eggs and will transform through the Chibi mini-game. There are three levels of Frens and to transform them, you must perform a series of missions to level up. You will be required to use a trainer to go on these missions. Your trainer could be your existing Chibis or selected other NFT avatars. Learn more about the game here: <a href="https://docs.chibilabs.io/chibi-frens-game/game" target="_blank" rel="noreferrer">Game Docs</a>.
            `
        ],
        [
            "Where can I buy a Chibi?",
            `All 3 generations of Chibis can be purchased from either OpenSea or LooksRare:
            <ui>
                <li><a href="https://opensea.io/collection/chibi-labs" target="_blank" rel="noreferrer">OpenSea Chibi Collections</a></li>
                <li>Looksrare:
                    <ul>
                        <li><a target="_blank" rel="noreferrer" href="https://looksrare.org/collections/0x4Ef0Fe82B42B6104BbcEB69E764AbD2050aCfdd4">Chibi Genesis</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://looksrare.org/collections/0xC49a9AB342b6ea66792D4110e9cA0ab36e3a5674">Chibi Apes</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://looksrare.org/collections/0x5AEb2a703323F69b20F397BCB7B38610EC37237b">Chibi Galaxy</a></li>
                    </ul>
                </li>
            </ui>
            `
        ],
        [
            "Who is Chibi Labs?",
            `We are a collective of artists and tech entrepreneurs looking to expand the nexus between creativity, community and collaboration into the Metaverse and beyond. <a href="https://ChibiLabs.io" target="_blank" rel="noreferrer">Visit the main Chibi Labs website here.</a>`
        ],
        [
            "I want to collab with you. Who can I contact?",
            `
                Please fill out the following form if you're interested in allow-list spots for Chibi Frens. We will reach out to you!<br></br>
                <a href="https://forms.gle/gudGgLg6ndBiXu3X7" target="_blank" rel="noreferrer">https://forms.gle/gudGgLg6ndBiXu3X7</a>
            `
        ],
        [
            "How do I know I am on the allow list?",
            `
                Simply visit <a href="https://frens.chibilabs.io/allow-list-check">https://frens.chibilabs.io/allow-list-check</a> and enter click on "Check"!
            `
        ],
    ]
}
