import './Navigation.scss'
import { ConnectButton } from "./ConnectButton";
import { Address } from "./Address";
import { Link } from 'react-router-dom';

export const MintNavigation = ({ showConnectButton, showBackButton }) => {
    return (
        <div className="topnav">
            <div className="container">
                <a href="/" className="logo">
                    <img src="images/logo.png" />
                </a>
                <div className="navigation" id="links">
                    <Address />
                    {showBackButton && <Link to="/">Back Home</Link>}
                    {showConnectButton && <ConnectButton />}
                </div>
            </div>
        </div>
    )
}