import './Button.scss'
import './Mint.scss'
import { useCallback } from "react";

export function MintButton(props) {
    const {
        mintPaused = true,
        onClick = () => {
        }
    } = props;

    const onButtonClick = useCallback(() => {
        if (!mintPaused) {
            onClick();
        }
    }, [mintPaused, onClick]);
    return (
        <>
            <div onClick={onButtonClick} className={`button mint ${mintPaused ? 'disabled' : ''}`}>
                <img src={`svg/button.svg`} alt={'Mint Button'} />
                <span>Mint</span>
            </div>
        </>
    )
}