import './Hero.scss'
import { Link } from 'react-router-dom';

export function Hero() {
    return (
        <div className="hero">
            <div className="container flex-grid">
                <div className="col-2">
                    <img src="svg/fire-2.svg" className="fire" />
                    <img src="images/eggs.gif" className="fren" />
                </div>
                <div className="col-2 centered-xs">
                    <h1>Chibi Frens: <br></br> More than <br></br> just a <span className="pink">pet</span>.</h1>
                    <Link to="/public">
                        <div className="button mint">
                            <img src={`svg/button.svg`} />
                            <span>Mint now</span>
                        </div>
                    </Link>
                </div>
            </div>
            <img src="svg/background-hero.svg" className="background-hero" />
        </div>
    )
}