import { useEffect, useRef } from 'react';
import LazyLoad from "react-lazyload";
import './Slider.scss';

function SliderInner({ images }) {
    const inputEl = useRef(null);
    let slideIndex = 0;

    useEffect(() => {
        function showSlides() {
            let i;
            const slides = inputEl.current.children;
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            slideIndex++;
            if (slideIndex > slides.length) { slideIndex = 1 }
            slides[slideIndex - 1].style.display = "block";
            setTimeout(showSlides, 3000)
        }
        showSlides()
    }, [])
    return (
        <div className="slider" ref={inputEl}>
            {
                images.map((image) => {
                    return (
                        <div key={image} className="slide fade">
                            <img src={image} />
                        </div>
                    )
                })
            }
        </div >
    )
}

export function Slider(props) {
    return (
        <LazyLoad offset={300}>
            <SliderInner {...props} />
        </LazyLoad>

    )
}