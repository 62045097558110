import './FAQ.scss';
import { TEXT } from '../text';

export function FAQ() {
    function handleClick(event) {
        event.target.classList.toggle("active");
        const content = event.target.nextElementSibling;
        if (content.style.display === "block") {
            content.style.display = "none";
        } else {
            content.style.display = "block";
        }
    }

    return (
        <div className="faq" id="faq">
            <div className="centered">
                <h1 className="title centered-text">FAQ</h1>
            </div>
            <div className="container">
                {
                    TEXT.FAQ.map((question) => {
                        return (
                            <div key={question[0]} className="collapsible">
                                <div className="collapsible-title" onClick={handleClick}>
                                    {question[0]}
                                </div>
                                <div className="collapsible-content">
                                    <div dangerouslySetInnerHTML={{ __html: question[1] }}></div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {/* <img src="images/cloud-top.png" className="cloud-top" /> */}
        </div>
    )
}