import { ethers } from "ethers";
import FrensABI from "../abis/frensABI.json";
import { ALCHEMY_API_KEY, getChibiContractInfo } from "../constants";

export const isAddressAllowListed = async ({ proof, address }) => {
    const provider = ethers.providers.getDefaultProvider("homestead", {
        alchemy: ALCHEMY_API_KEY
    });
    const contractInfo = getChibiContractInfo("gen4");
    const contract = new ethers.Contract(contractInfo, FrensABI, provider);

    const result = await contract.isAddressAllowlisted(proof, address);
    return result;
};

export const getMintInfo = async () => {
    const provider = ethers.providers.getDefaultProvider("homestead", {
        alchemy: ALCHEMY_API_KEY
    });
    const contractInfo = getChibiContractInfo("gen4");
    const contract = new ethers.Contract(contractInfo, FrensABI, provider);

    /**
     *     bool public claimPaused = true;
     *     bool public publicMintPaused = true;
     *     bool public allowlistMintPaused = true;
     */
    const claimPaused = await contract.claimPaused();
    const publicMintPaused = await contract.publicMintPaused();
    const allowListMintPaused = await contract.allowlistMintPaused();
    const totalSupply = await contract.totalSupply();
    const collectionSize = await contract.collectionSize();
    const maxItemsPerWallet = await contract.maxItemsPerWallet();

    return {
        claimPaused,
        allowListMintPaused,
        publicMintPaused,
        totalSupply: totalSupply.toNumber(),
        collectionSize: collectionSize.toNumber(),
        maxItemsPerWallet: maxItemsPerWallet.toNumber(),
    };
};