import { Navigation } from "../components/Navigation";
import { Hero } from "../sections/Hero";
import { Clouds } from "../components/Clouds";
import { About } from "../sections/About";
import { Evolve } from "../sections/Evolve";
import { Ready } from "../sections/Ready";
import { Game } from "../sections/Game";
import { Stars } from "../components/Stars";
import { Viewer } from "../sections/Viewer";
import { FAQ } from "../sections/FAQ";
import { Footer } from "../sections/Footer";

export const Home = () => {
    return (
        <>
            <Navigation />
            <Hero />
            <div className="bg-blue">
                <Clouds />
                <About />
                <Evolve />
                <Ready />
                <Game />
            </div>
            <div className="bg-blue-reverted">
                <Stars />
                <Viewer />
                <FAQ />
                <Footer />
            </div>
        </>
    )
}