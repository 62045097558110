import { useMintContext } from "./MintProvider";

export const CollectionInfo = ({ isPublic = false }) => {
    const {
        mintInfo: {
            totalSupply,
            collectionSize,
        }
    } = useMintContext();
    const size = isPublic ? "10000" : collectionSize;


    return totalSupply ? (
        <div className={'mint-collection-info'}>
            {totalSupply} / {size}
        </div>
    ) : null;
}