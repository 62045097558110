import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect } from "../utilities/connect";
import { getMintInfo } from "../utilities/check";
import { MintNavigation } from "./MintNavigation";
import { Clouds } from "./Clouds";
import { MintStatus } from "./MintStatus";

import "./Mint.scss";
import { MintFooter } from "../sections/MintFooter";

export const STATUS = {
    ERROR: 'error',
    SUCCESS: 'success',
    NONE: 'none',
    INFO: 'info',
};

export const MintContext = React.createContext({});

export const MintProvider = ({ children, showConnectButton = false, showBackButton = false }) => {
    const [connecting, setConnecting] = useState(false);
    const [status, setStatus] = useState();
    const [walletInfo, setWalletInfo] = useState({});
    const [mintInfo, setMintInfo] = useState({});

    window.ethereum?.on('accountsChanged', async (accounts) => {
        setWalletInfo({ address: accounts?.[0] });
    });

    window.ethereum?.on('chainChanged', (networkId) => {
        if (networkId !== "0x1") {
            setStatus({
                type: STATUS.ERROR,
                message: "Please change to Ethereum Mainnet!"
            });
        } else {
            setStatus();
        }
    });

    const updateMintInfo = useCallback(async () => {
        try {
            const mintInfo = await getMintInfo();
            setMintInfo(mintInfo);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleConnect = useCallback(async () => {
        try {
            const { address, provider, signer } = await connect();
            setWalletInfo({
                address,
                provider,
                signer,
            });
        } catch (error) {
            console.log('connect', error);
            setStatus({
                type: STATUS.ERROR,
                message: error?.error?.message || error?.reason || error?.message
            });
        }
    }, [setWalletInfo, setMintInfo, setStatus]);

    useEffect(() => {
        updateMintInfo();
    }, []);

    const contextValues = {
        status,
        mintInfo,
        walletInfo,
        handleConnect,
        setStatus,
        connecting,
        setConnecting,
        updateMintInfo,
    };

    return (
        <MintContext.Provider value={contextValues}>
            <MintNavigation showConnectButton={showConnectButton} showBackButton={showBackButton} />
            <div className="mint-bg-blue">
                <div className="mint">
                    <MintStatus status={status} />
                    <div className="container flex-grid">
                        {children}
                    </div>
                </div>
                <Clouds />
            </div>
            <div className="bg-blue-reverted">
                <MintFooter />
            </div>
        </MintContext.Provider>
    );
};

export const useMintContext = () => useContext(MintContext);