import { RectangleBox } from '../components/RectangleBox';
import './Game.scss';
import { TEXT } from '../text';
import { Slider } from '../components/Slider';
import { GAME_DOCS } from '../constants';

export function Game() {
    return (
        <div className="game" id="game">
            <div className="container">
                <div className="flex-grid">
                    <div className="col-2">
                        <h1 className="title">{TEXT.GAME[0]}</h1>
                        <RectangleBox
                            text={TEXT.GAME[1]}
                            buttonText="Learn More"
                            onClick={
                                () => window.open(GAME_DOCS, '_blank').focus()
                            }
                        />

                    </div>
                    <div className="col-2">
                        <Slider images={[
                            "images/game-ui-1.jpg",
                            "images/game-ui-2.jpg",
                            "images/game-ui-3.jpg",
                        ]} />
                    </div>
                </div>
            </div>
        </div>
    )
}