import { ethers } from "ethers";
import FrensABI from "../abis/frensABI.json";
import { getChibiContractInfo } from "../constants";

export const publicMint = async ({ amount, signer }) => {
    const contractInfo = getChibiContractInfo("gen4");
    const contract = new ethers.Contract(contractInfo, FrensABI, signer);

    const receipt = await contract.publicMint({
        value: ethers.utils.parseEther(amount.toString()),
    });
    // const receipt = await tx.wait();
    return receipt;
};

export const claim = async ({ gen1TokenIds, gen2TokenIds, gen3TokenIds, signer } = {}) => {
    const contractInfo = getChibiContractInfo("gen4");
    const contract = new ethers.Contract(contractInfo, FrensABI, signer);
    return await contract.claim(gen1TokenIds, gen2TokenIds, gen3TokenIds);
};

export const specialClaim = async ({ tokenIds, signer } = {}) => {
    const contractInfo = getChibiContractInfo("gen4");
    const contract = new ethers.Contract(contractInfo, FrensABI, signer);
    return await contract.specialClaim(tokenIds);
};


export const allowListMint = async ({ amount, proof, signer }) => {
    const contractInfo = getChibiContractInfo("gen4");
    const contract = new ethers.Contract(contractInfo, FrensABI, signer);

    const receipt = await contract.allowlistMint(proof, {
        value: ethers.utils.parseEther(amount.toString()),
    });
    // const receipt = await tx.wait();
    return receipt;
};

export const isGen1Chibi = (chibi) => {
    const contractAddress = getChibiContractInfo("gen1");
    return chibi?.contract?.address === contractAddress;
};

export const isGen2Chibi = (chibi) => {
    const contractAddress = getChibiContractInfo("gen2");
    return chibi?.contract?.address === contractAddress;
};

export const isGen3Chibi = (chibi) => {
    const contractAddress = getChibiContractInfo("gen3");
    return chibi?.contract?.address === contractAddress;
};

export const getGen1Chibis = (chibis) => {
    return chibis.filter(chibi => isGen1Chibi(chibi));
};

export const getGen2Chibis = (chibis) => {
    return chibis.filter(chibi => isGen2Chibi(chibi));
};

export const getGen3Chibis = (chibis) => {
    return chibis.filter(chibi => isGen3Chibi(chibi));
};

export const getGen2And3Chibis = (chibis) => {
    return chibis.filter(chibi => isGen2Chibi(chibi) || isGen3Chibi(chibi));
};

export const isEligibleForSpecialClaim = (chibis) => {
    const gen1Chibis = getGen1Chibis(chibis);
    const gen2Chibis = getGen2Chibis(chibis);
    const gen3Chibis = getGen3Chibis(chibis);
    const gen1Amount = gen1Chibis?.length;

    return gen1Amount
        && (gen2Chibis?.length >= gen1Amount)
        && (gen3Chibis?.length >= gen1Amount);
};
