import { useMintContext } from "./MintProvider";

function cut(str, cutStart) {
    // return str.substr(0, cutStart) + '...' + str.substr(cutEnd + 1);
    return str.substr(0, cutStart);
}

export function Address() {
    const { walletInfo = {} } = useMintContext();
    const { address } = walletInfo;
    if (!address) {
        return null;
    }
    return (
        <div className="address">
            <h3>{cut(address, 6, 35)}</h3>
        </div>
    );
}