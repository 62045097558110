import { useState } from "react";
import "./Check.scss";
import { MintProvider, STATUS } from "../components/MintProvider";
import { Helmet } from "react-helmet-async";
import { createError } from "../utilities/error";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ALCHEMY_API_KEY, getChibiContractInfo } from "../constants";
import { hasChibiBeenUsedToClaim } from "../utilities/utils";
import { ethers } from "ethers";

function Check() {
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState();
    const [status, setStatus] = useState();

    function handleChange(event) {
        const value = event.target.value;
        setFormData({
            ...formData,
            [event.target.name]: value
        });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const { collection, tokenId } = formData;
        try {
            setLoading(true);
            const contractAddress = getChibiContractInfo(collection);
            const provider = new ethers.providers.AlchemyProvider('homestead', ALCHEMY_API_KEY);
            const isClaimed = await hasChibiBeenUsedToClaim({ contractAddress, tokenId, provider });
            if (isClaimed) {
                setStatus({
                    type: STATUS.ERROR,
                    message: "Egg claimed already!"
                });
            } else {
                setStatus({
                    type: STATUS.SUCCESS,
                    message: "Egg unclaimed yet!"
                });
            }
        } catch (error) {
            let message = createError(error);
            if (message.includes("missing provider")) {
                message = "Please use a browser with MetaMask installed!";
            }
            setStatus({
                type: STATUS.ERROR,
                message,
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="check">
            <Helmet>
                <title>Chibi Frens - Claim Check</title>
            </Helmet>
            <h1 className="centered-text">
                Claim Check
            </h1>
            <h3 className="centered-text">
                Check if a Chibi has been used to claim an Egg.
            </h3>
            <p className={`status ${status?.type}-status`} dangerouslySetInnerHTML={{ __html: status?.message }} />
            <form onSubmit={handleSubmit}>
                <select
                    onChange={handleChange}
                    value={formData?.collection}
                    name="collection"
                    placeholder="Select the collection"
                    required
                >
                    <option value="">--Please choose a collection--</option>
                    <option value="gen1">Chibi Genesis</option>
                    <option value="gen2">Chibi Apes</option>
                    <option value="gen3">Chibi Galaxy</option>
                </select>
                <input
                    type="text"
                    name="tokenId"
                    onChange={handleChange}
                    value={formData?.tokenId || ''}
                    placeholder="Token ID"
                    required
                />
                {
                    loading ? (
                        <div className="loading">
                            <LoadingSpinner />
                        </div>
                    ) : (
                        <button>Check</button>
                    )
                }
            </form>
        </div>
    );
}

export const CheckWithProvider = (props) => {
    return (
        <MintProvider showBackButton={true}>
            <Check {...props} />
        </MintProvider>
    );
}

