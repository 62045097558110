import '@google/model-viewer';

export function ModelViewer({ link, animation }) {
    return (
        <model-viewer
            // style={{ height, width }}
            src={link}
            poster="/assets/images/loading-min.jpg"
            alt="A 3D model of a Chibi"
            shadow-intensity="0.5"
            auto-rotate
            camera-controls
            environment-image="neutral"
            ar
            ar-modes="webxr scene-viewer quick-look"
            loading="eager"
            animation-name={animation}
            autoplay
        >
            <div className="progress-bar hide" slot="progress-bar">
                <div className="update-bar"></div>
            </div>
        </model-viewer>
    )
}
