import { STATUS } from "../components/MintProvider";

export function getRandomItem(items) {
    return items[Math.floor(Math.random() * items.length)];
}

export function getRandomItems(items, number) {
    const shuffled = items.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, number);
}

export function createErrorMessage(error) {
    return {
        type: STATUS.ERROR,
        message: error?.response?.data?.message
            || error?.data?.message
            || error?.error?.message
            || error?.reason
            || error?.message
            || error
    };
}