import LazyLoad from "react-lazyload";
import { MintButton } from "../components/MintButton";
import { useCallback, useEffect, useState } from "react";
import { PRICE } from "../constants";
import { allowListMint, publicMint } from "../utilities/mint";
import { MintProvider, STATUS, useMintContext } from "../components/MintProvider";
import * as ApiUtils from "../utilities/api";
import { CollectionInfo } from "../components/CollectionInfo";
import { Helmet } from "react-helmet-async";
import { ConnectButton } from "../components/ConnectButton";

const ETHERSCAN_BASE_URL = `https://etherscan.io`;

const Mint = ({ type = 'public' }) => {
    const doingAllowList = type === 'allow-list';

    const {
        walletInfo = {},
        mintInfo = {},
        setStatus,
        updateMintInfo = () => {
        },
    } = useMintContext()

    const {
        address,
        signer,
    } = walletInfo;

    const {
        publicMintPaused = true,
        maxItemsPerWallet = 2,
        allowListMintPaused = true,
    } = mintInfo;

    const [quantity, setQuantity] = useState(1);
    const [allowMint, setAllowMint] = useState(!doingAllowList);
    const [checkingAllowList, setCheckingAllowList] = useState(false);
    const [proof, setProof] = useState();

    const changeQuantity = useCallback((action) => {
        // console.log(`[changeQuantity] `, { quantity, action, maxItemsPerWallet })
        let tempQuantity = quantity;
        switch (action) {
            case 'increment':
                if (quantity < maxItemsPerWallet)
                    tempQuantity += 1;
                break;
            case 'decrement':
                if (quantity > 1)
                    tempQuantity -= 1;
                break;
            default:
                console.log(`[changeQuantity] INVALID ACTION`);
        }

        setQuantity(tempQuantity);

    }, [quantity, maxItemsPerWallet]);

    useEffect(() => {
        // console.log(`[Mint]`, { address, doingAllowList, proof });
        if (address && doingAllowList && !proof) {
            setCheckingAllowList(true);
            ApiUtils.getAllowListProof({ address, signer }).then(async (proof) => {
                console.log(`[Mint][getAllowListProof] proof: `, proof);
                if (proof?.length > 0) {
                    setProof(proof);
                }
                setAllowMint(proof?.length > 0);
            }).catch((error) => {
                console.error(error);
                setStatus({
                    type: STATUS.ERROR,
                    message: error?.error?.message || error?.reason || error?.message
                });
            }).finally(() => {
                setCheckingAllowList(false);
            });
        }
    }, [doingAllowList, address, proof]);

    const handleAllowListMint = useCallback(async () => {
        if (allowListMintPaused) {
            setStatus({
                type: STATUS.INFO,
                message: 'The public mint has been paused.'
            });
            return;
        }

        setStatus();

        try {
            const amount = parseFloat(parseFloat(quantity * PRICE).toPrecision(2))
            const receipt = await allowListMint({
                amount,
                proof,
                signer,
            })
            setStatus({
                type: STATUS.SUCCESS,
                message: `<a href="${ETHERSCAN_BASE_URL}/tx/${receipt?.hash}" target="_blank" rel="noreferrer">Check out your transaction on Etherscan</a>.`,
            });

            updateMintInfo();
        } catch (error) {
            setStatus({
                type: STATUS.ERROR,
                message: error?.error?.message || error?.reason || error?.message
            });
        }
    }, [allowListMintPaused, quantity, signer, proof, setStatus, updateMintInfo]);

    const handlePublicMint = useCallback(async () => {
        if (publicMintPaused) {
            setStatus({
                type: STATUS.INFO,
                message: 'The public mint has been paused.'
            });
            return;
        }

        setStatus();
        try {
            const amount = parseFloat(parseFloat(quantity * PRICE).toPrecision(2))
            const receipt = await publicMint({
                amount,
                signer,
            })
            setStatus({
                type: STATUS.SUCCESS,
                message: `<a href="${ETHERSCAN_BASE_URL}/tx/${receipt?.hash}" target="_blank" rel="noreferrer">Check out your transaction on Etherscan</a>.`,
            });

            updateMintInfo();
        } catch (error) {
            setStatus({
                type: STATUS.ERROR,
                message: error?.error?.message || error?.reason || error?.message
            });
        }
    }, [quantity, signer, publicMintPaused, setStatus, updateMintInfo]);

    const minQuantityReached = quantity === 1;
    const maxQuantityReached = quantity === maxItemsPerWallet;

    const onMintButtonClicked = doingAllowList ? handleAllowListMint : handlePublicMint;
    const mintPaused = doingAllowList ? (allowListMintPaused || !proof) : publicMintPaused;

    const decrementClassName = `mint-quantity-button ${minQuantityReached ? 'disabled' : ''}`;
    const incrementClassName = `mint-quantity-button ${maxQuantityReached ? 'disabled' : ''}`;

    return (
        <>
            <Helmet>
                <title>Chibi Frens - {doingAllowList ? 'Allow List' : 'Public'} Mint</title>
            </Helmet>
            <div className="col-2 centered">
                <LazyLoad offset={300}>
                    <video src="videos/walk.mp4" muted autoPlay loop playsInline />
                </LazyLoad>
            </div>
            <div className="col-2 centered centered-text mint">
                <h1>{doingAllowList ? 'Allow List' : 'Public'} Mint</h1>
                {!address && (<>
                    <h3>Connect to start minting</h3>
                    <ConnectButton />
                </>)}
                {address && allowMint && ((doingAllowList && proof?.length > 0) || !doingAllowList) && (<>
                    <h3>How many Chibi Frens would you like to mint?</h3>
                    <CollectionInfo isPublic />
                    <div className={'mint-quantity'}>
                        <div className={decrementClassName} onClick={() => changeQuantity('decrement')}>-</div>

                        <input
                            type="number"
                            value={quantity}
                            name="number"
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value > 20) {
                                    return null
                                }
                                if (value < 0) {
                                    return null;
                                }
                                setQuantity(e.target.value)
                            }}
                        />
                        {/* <div className={'quantity-value'}>{quantity}</div> */}
                        <div className={incrementClassName} onClick={() => changeQuantity('increment')}>+
                        </div>
                    </div>
                    <span className={'mint-cost'}>{((PRICE * quantity).toFixed(2))} ETH + gas</span>
                    <MintButton mintPaused={mintPaused} onClick={onMintButtonClicked} />
                </>)}
                {address && doingAllowList && checkingAllowList && (<>
                    <h3>Checking if you&apos;re on the allowed list.</h3>
                </>)}
                {address && doingAllowList && !allowMint && !checkingAllowList && (
                    <>
                        <h3 className="mb-0">😔</h3>
                        <h3>{`We're sorry fren, you're not allowed to mint.`}</h3>
                    </>
                )
                }
            </div >
        </>
    )
}

const MintWithProvider = (type, props) => {
    const viewProps = {
        ...props,
        type,
    }

    return (
        <MintProvider showConnectButton={false}>
            <Mint {...viewProps} />
        </MintProvider>
    )
}

export const PublicMintWithProvider = (props) => {
    return MintWithProvider('public', props);
}

export const AllowListMintWithProvider = (props) => {
    return MintWithProvider('allow-list', props);
}